import { apiPost, apiGet, apiDelete, handleError, refreshError } from '@/helpers/api'

const namespaced = true

const DEFAULT_INPUTS_STATE = {
    email: '',
    client: '',
    role: '',
    valid: 3,
    master: false,
}

const state = () => ({
    inputs: { ...DEFAULT_INPUTS_STATE },
    users: [],
    ownedUsersOptions: [],
    invitesTableRefreshKey: 0,
    loading: false,
    deleting: false,

    permissions: [],
})

const mutations = {
    UPDATE_INPUTS(state, inputs) {
        state.inputs = { ...inputs }
    },

    SET_INPUT(state, { name, value }) {
        state.inputs[name] = value
    },

    RESET_INPUTS_STATE(state) {
        state.inputs = { ...DEFAULT_INPUTS_STATE }
    },

    UPDATE_REFRESH_KEY(state) {
        state.invitesTableRefreshKey++
    },

    SET_LOADING(state, value, key = 'loading') {
        console.log(key)
        state[key] = value
    },
}

export const actions = {
    updateInputs({ commit, state }, { name, value }) {
        const inputs = { ...state.inputs }
        inputs[name] = value
        commit('UPDATE_INPUTS', inputs)
    },

    async resendInvite({ dispatch, commit }, { vm, id }) {
        try {
            commit('SET_LOADING', true)
            await apiGet(vm.route('invite.resend', { invite: id }))
            commit('SET_LOADING', false)
            commit('UPDATE_REFRESH_KEY')

            const notification = {
                type: 'success',
                text: vm.$t('notifications.success_invite_resend'),
            }

            dispatch('notification/add', notification, { root: true })
        } catch (error) {
            commit('SET_LOADING', false)
            refreshError(dispatch, vm)
        }
    },

    async sendInvite({ dispatch, commit, state }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })

            let params = { ...state.inputs }
            params.valid *= 24

            await apiPost(vm.route('invite.store'), params)

            const notification = {
                type: 'success',
                text: vm.$t('§ui.notification_success_sent'),
            }
            dispatch('notification/add', notification, { root: true })

            commit('RESET_INPUTS_STATE')
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async impersonate({ dispatch }, { vm, user }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiGet(vm.route('permission.impersonate', { user }))

            if (response.success && response.success === true) {
                dispatch('hideLoader', null, { root: true })
                await dispatch(
                    'user/getUserData',
                    { vm },
                    {
                        root: true,
                    }
                )

                vm.$router.push({ name: 'Home' })
            }
        } catch (error) {
            dispatch('hideLoader', null, { root: true })
            refreshError(dispatch, vm)
        }
    },

    async deleteInvite({ dispatch, commit }, { vm, id }) {
        try {
            commit('SET_LOADING', true, 'deleting')
            await apiDelete(vm.route('invite.destroy', { invite: id }))
            commit('SET_LOADING', false, 'deleting')
            commit('UPDATE_REFRESH_KEY')

            const notification = {
                type: 'success',
                text: vm.$t('§users.invite_deleted_successfully'),
            }

            dispatch('notification/add', notification, { root: true })
            dispatch('modal/SET_CLOSE', null, { root: true })
        } catch (error) {
            commit('SET_LOADING', false, 'deleting')
            refreshError(dispatch, vm)
        }
    },
}

const getters = {
    getRoles: (state) => state.roles,
}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
