<template>
    <Button
        :label="getLabel"
        :class="classes"
        :disabled="disabled"
        @click="handleClick"
    />
</template>

<script>
import Button from 'primevue/button'

export default {
    name: 'CoreTableCellButton',

    components: { Button },

    props: {
        label: {
            type: String,
            default: '',
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        condensed: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classes() {
            return {
                'core-table-cell-button': true,
                'core-table-cell-button--disabled': this.disabled,
            }
        },

        getLabel() {
            return this.$t(this.label)
        },
    },

    methods: {
        handleClick() {
            if (!this.disabled) {
                this.$emit('click')
            }
        },
    },
}
</script>

<style lang="scss">
.core-table-cell-button {
    height: auto;
    width: 100%;
    background: $cn-50;
    padding: 8px 14px;
    border: 1px solid $cn-50;
    text-align: left;

    transition: background-color 0.5s, color 0.5s, border-color 0.5s, box-shadow 0.5s;

    &.p-button {
        border-radius: 4px;
    }

    & > .p-button-label {
        color: $cs;
        font-weight: 300;
        font-size: 1.4rem;
    }

    &.p-button:enabled:hover {
        cursor: pointer;
        background: $cp-50;
        border: 1px solid $cp-50;
    }

    &.p-button:focus {
        border: 1px solid $cp-75;
    }

    &.core-table-cell-button--disabled.p-component:disabled {
        opacity: 1;
    }

    &--disabled {
        cursor: not-allowed;
        background: $cn-10;

        & > .p-button-label {
            color: $cn;
        }
    }

    &.core-table-cell-button--disabled.p-button:enabled:hover {
        cursor: not-allowed;
        background: $cn-10;

        & > .p-button-label {
            color: $cn;
        }
    }
    &.core-table-cell-button--disabled.p-button:focus {
        background: $cn-10;
        outline: none;

        & > .p-button-label {
            color: $cn;
        }
    }
}
</style>
