import { apiPost, apiGet, apiPatch, apiDelete, handleError, refreshError } from '@/helpers/api'

const namespaced = true

const compareObjects = (fetchedState, currentState) => {
    return Object.keys(fetchedState).every((key) => fetchedState[key] === currentState[key])
}

const normalizeResidualValuesTableReceivingObject = (tableObject) => {
    const residualValues = {}

    // { 0.0: [val0, val1...], 0.1: [val0, val1...], ... 2.0: [val0, val1...]}
    Object.keys(tableObject)
        .sort() // sort 1 behind 0.9 and 2 behind 1.9
        .forEach((key) => {
            const column = Object.keys(tableObject[key]).map((columnKey) => {
                const value = tableObject[key][columnKey]
                return {
                    time: columnKey,

                    residual_value: value,
                }
            })

            const utilKey = key.length === 1 ? `${key}.0` : key
            residualValues[utilKey] = column
        })

    return residualValues
}

const DEFAULT_UNIT_TYPE_STATE = {
    id: null,
    name: '',
    identifier: null,
    customization: 0,
    is_stationary: '1',
    industry: '',
    value: null,
    refurbishment: null,
    buy_back: false,
    allow_delete: false,
    planned_usage_per_a: null,
    maximum_usage_per_a: null,
    billing_units: [],
    residual_value_base_usage_term: null,
    residual_value_zero_usage: null,
    residual_value_base_usage: null,
    residual_value_double_usage: null,
    residual_value_1_year: null,
    residual_value_5_years: null,
    residual_value_5_years_full_usage: null,
    residual_value_5_years_lower_usage: null,
    residual_value_5_years_higher_usage: null,
    selectedRvtModel: null,
    currency_code: null,
}

const state = () => ({
    inputs: { ...DEFAULT_UNIT_TYPE_STATE },
    residual_values_table: {},
    isStationaryRadioOptions: [
        { label: 'unitTypes.moveable', value: 1 },
        { label: 'unitTypes.stationary', value: 2 },
    ],
    unitTypeOptions: [],
})

const mutations = {
    SET_INPUT(state, { name, value }) {
        Vue.set(state.inputs, name, value)
    },

    RESET_INPUTS(state) {
        state.inputs = { ...DEFAULT_UNIT_TYPE_STATE }
    },

    UPDATE_FORM_STATE(state, { newState }) {
        state.unitType = newState
    },

    UPDATE_SELECT_OPTIONS(state, { newState }) {
        state.industries = newState
    },

    RESET_RESIDUAL_VALUE_INPUTS(state) {
        state.unitType = {
            ...state.unitType,
            residual_value_1_year: null,
            residual_value_5_years: null,
            residual_value_5_years_full_usage: null,
            residual_value_5_years_lower_usage: null,
            residual_value_5_years_higher_usage: null,
        }
    },

    UPDATE_RESIDUAL_VALUES_TABLE(state, { data }) {
        state.residual_values_table = { ...data }
    },

    UPDATE_RESIDUAL_VALUES_TABLE_UNIT_TYPE_INPUTS(state, { data }) {
        const inputs = {
            buy_back: data.buy_back,
            value: data.value,
            residual_value_base_usage_term: data.residual_value_base_usage_term,
            residual_value_zero_usage: data.residual_value_zero_usage,
            residual_value_base_usage: data.residual_value_base_usage,
            residual_value_double_usage: data.residual_value_double_usage,
        }
        state.residual_values_table_unit_type_inputs = { ...inputs }
    },

    DELETE_RESIDUAL_VALUES_TABLE(state) {
        state.residual_values_table = {}
    },
}

export const actions = {
    updateUnitTypeFields({ commit, state }, { fieldName, newValue }) {
        const unitTypeCopy = Object.assign(state.unitType, {})
        if (unitTypeCopy.hasOwnProperty(fieldName)) {
            unitTypeCopy[fieldName] = newValue
            commit('UPDATE_FORM_STATE', { newState: unitTypeCopy })
        }
    },

    resetUnitTypeFormState({ commit }) {
        commit('RESET_STATE')
    },

    saveResidualValuesTable({ commit }, { data }) {
        commit('UPDATE_RESIDUAL_VALUES_TABLE', { data })
    },

    async save({ state, dispatch }, { vm, rvt }) {
        try {
            const data = {
                ...state.inputs,
                customization: state.inputs.customization / 100,
            }

            const { buy_back, ...dataWithoutBuyBack } = data

            if (buy_back) {
                dataWithoutBuyBack.residual_values_table = { ...rvt }
            }

            const response = await apiPost(vm.route('unit_type.store'), {
                ...dataWithoutBuyBack,
            })

            // add new unit type to the view vars state and local storage
            await dispatch(
                'addItemToViewVars',
                {
                    listName: 'unitType',
                    item: {
                        value: response.unit_type_id,
                        label: response.unit_type_name,
                    },
                    vm,
                },
                { root: true }
            )

            return response
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async update({ dispatch, state }, { vm, rvt }) {
        try {
            const data = {
                ...state.inputs,
                customization: state.inputs.customization / 100,
            }

            const { buy_back, ...dataWithoutBuyBack } = data

            if (buy_back) {
                dataWithoutBuyBack.residual_values_table = { ...rvt }
            }

            const response = await apiPatch(vm.route('unit_type.update', [state.inputs.id]), {
                ...dataWithoutBuyBack,
            })

            if (response.status === 'success') {
                dispatch(
                    'updateItemInViewVars',
                    {
                        listName: 'unitType',
                        item: {
                            value: dataWithoutBuyBack.id,
                            label: dataWithoutBuyBack.name,
                        },
                        vm,
                    },
                    { root: true }
                )

                const notification = {
                    type: 'success',
                    text: vm.$t('notifications.success_updated', {
                        name: `Equipment Type #${dataWithoutBuyBack.id} (${dataWithoutBuyBack.name})`,
                    }),
                }

                dispatch('notification/add', notification, { root: true })
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async deleteUnitType({ dispatch, state }, { vm }) {
        try {
            const response = await apiDelete(
                vm.route('unit_type.destroy', {
                    type: state.inputs.id,
                })
            )

            if (response.status === 'success') {
                const notification = {
                    type: 'success',
                    text: vm.$t('notifications.success_deleted', {
                        name: state.inputs.name,
                    }),
                }

                dispatch(
                    'removeItemFromViewVars',
                    {
                        listName: 'unitType',
                        item: {
                            value: state.inputs.id,
                            label: state.inputs.name,
                        },
                        vm,
                    },
                    { root: true }
                )

                await vm.$router.push({
                    name: 'UnitTypeIndex',
                })

                dispatch('notification/add', notification, { root: true })
            }

            if (response.status === 'fail') {
                const notification = {
                    type: 'error',
                    text: vm.$t('notifications.error_unit_used', {
                        name: state.inputs.name,
                    }),
                }

                dispatch('notification/add', notification, { root: true })
            }

            return response
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async getItemData({ dispatch, commit }, { itemId, vm }) {
        try {
            const data = await apiGet(vm.route('unit_type.show', [itemId]))
            if (data === null) {
                return
            }
            Object.keys(data).forEach((dataKey) => {
                let name, value

                switch (dataKey) {
                    case 'customization':
                        name = 'customization'
                        value = data.customization * 100
                        break
                    case 'oem_industry_id':
                        name = 'industry'
                        value = data.oem_industry_id
                        break
                    case 'residual_value_1_year':
                        commit('SET_INPUT', { name: 'buy_back', value: true })
                    case 'residual_value_5_years_full_usage':
                        commit('SET_INPUT', { name: 'buy_back', value: true })
                    default:
                        name = dataKey
                        value = data[dataKey]
                }

                commit('SET_INPUT', { name, value })
            })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async calculateRvt({ state, commit, dispatch }, { routeName }) {
        try {
            const inputs = {
                value: state.inputs.value,
                residual_value_1_year: state.inputs.residual_value_1_year,
                residual_value_5_years: state.inputs.residual_value_5_years,
                residual_value_5_years_full_usage: state.inputs.residual_value_5_years_full_usage,
                residual_value_5_years_lower_usage: state.inputs.residual_value_5_years_lower_usage,
                residual_value_5_years_higher_usage:
                    state.inputs.residual_value_5_years_higher_usage,
            }

            const data = await apiPost(routeName, {
                ...inputs,
                ajax: true,
            })

            return data.residual_values_table
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async getResidualValueTable({ state, commit, dispatch }, { routeName, openModal }) {
        try {
            dispatch('showLoader', null, { root: true })

            const inputs = {
                value: state.inputs.value,
                residual_value_1_year: state.inputs.residual_value_1_year,
                residual_value_5_years: state.inputs.residual_value_5_years,
                residual_value_5_years_full_usage: state.inputs.residual_value_5_years_full_usage,
                residual_value_5_years_lower_usage: state.inputs.residual_value_5_years_lower_usage,
                residual_value_5_years_higher_usage:
                    state.inputs.residual_value_5_years_higher_usage,
            }

            const data = await apiPost(routeName, {
                ...inputs,
                ajax: true,
            })

            const residualValues = normalizeResidualValuesTableReceivingObject(
                data.residual_values_table
            )

            const size = Object.keys(residualValues).length === 1 ? 'small' : 'large'

            commit('UPDATE_RESIDUAL_VALUES_TABLE', { data: residualValues })
            dispatch('hideLoader', null, { root: true })
            if (openModal !== false) {
                commit(
                    'modal/SET_OPEN',
                    {
                        name: 'ResidualValuesTableModal',
                        size,
                        title: 'unitTypes.modal_rvt_header',
                    },
                    { root: true }
                )
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },
}

const getters = {
    getIsStationaryRadioOptions: (state) => state.isStationaryRadioOptions,
    // is_stationary on the backend is the bool (0 for moveable or 1 for stationary)
    // here defined radio options are 1 for movable and 2 for stationary
    // to set is_stationary (can be 0 for moveable or 1 for stationary) in the state, add 1
    getIsStationarySelectedOption: (state) =>
        state.unitType.is_stationary === null ? '0' : Number(state.unitType.is_stationary) + 1,
}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
