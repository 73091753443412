import XHRUpload from '@uppy/xhr-upload'
import { format } from 'date-fns'
import {
    apiPost,
    apiGet,
    apiPatch,
    apiDelete,
    apiDownload,
    handleError,
    refreshError,
} from '@/helpers/api'
import ziggyRoute from '../../helpers/ziggyRoute'

const namespaced = true

const DEFAULT_CLIENT_STATE = {
    id: null,
    name: '',
    vat_identification_number: '',
    company_registration_number: '',
    country_id: '',
    enduser_industry_id: '',
    street: '',
    postal_code: '',
    city: '',
    contact_person: '',
    contact_email: '',

    invoice_emails: [],
    maintenance_emails: [],
    maintenance_email: '',

    phone: '',
    authorized_people: [],
    removable: false,
}

const DEFAULT_PERSON_STATE = {
    id: null,
    first_name: '',
    last_name: '',
    date_of_birth: '',
    country_id: '',
    passport_file_path: '',
}

const state = () => ({
    inputs: { ...DEFAULT_CLIENT_STATE },
    person: { ...DEFAULT_PERSON_STATE },
    countries: [],
    industries: [],
    clientOptions: [],
})

const mutations = {
    SET_INPUT(state, { name, value }) {
        const arrayFields = ['authorized_people', 'invoice_emails', 'maintenance_emails']

        if (arrayFields.includes(name) && !Array.isArray(value)) {
            return
        }

        state.inputs[name] = value
    },

    RESET_INPUTS(state) {
        state.inputs = { ...DEFAULT_CLIENT_STATE }
    },

    CLONE_ITEM(state) {
        state.inputs.id = null
        state.inputs.authorized_people = []
    },

    UPDATE_AUTH_PEOPLE_ARRAY(state, authorized_people) {
        state.inputs.authorized_people = [...authorized_people]
    },

    APPEND_TO_EMAILS_ARRAY(state, { group }) {
        state.inputs[group] = [...state.inputs[group], { email: '', type: 'to', name: '' }]
    },

    REMOVE_FROM_EMAILS_ARRAY(state, { group, index }) {
        const emails = state.inputs[group]
        state.inputs[group] = [...emails.slice(0, index), ...emails.slice(index + 1)]
    },

    UPDATE_EMAILS_OBJECT(state, { group, index, name, value }) {
        state.inputs[group][index][name] = value
    },

    SET_PERSON_INPUT(state, { name, value }) {
        state.person[name] = value
    },

    RESET_PERSON(state) {
        state.person = { ...DEFAULT_PERSON_STATE }
    },
}

export const actions = {
    resetFormState({ commit }) {
        commit('RESET_FORM_STATE')
    },

    async save({ state, dispatch, commit }, { vm }) {
        try {
            const { name } = state.inputs

            const data = await apiPost(vm.route('client.store'), {
                ...state.inputs,
            })

            commit('SET_INPUT', {
                name: 'id',
                value: data.client_id,
            })

            dispatch(
                'addItemToViewVars',
                {
                    listName: 'client',
                    item: { value: data.client_id, label: name },
                    vm,
                },
                { root: true }
            )

            return true
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async update({ dispatch, state }, { vm }) {
        try {
            const response = await apiPatch(
                vm.route('client.update', [state.inputs.id]),
                state.inputs
            )

            if (response.status === 'success') {
                const notification = {
                    type: 'success',
                    text: vm.$t('notifications.success_updated', {
                        name: state.inputs.name,
                    }),
                }

                // update client label in the view vars state and local storage
                dispatch(
                    'updateItemInViewVars',
                    {
                        listName: 'client',
                        item: {
                            value: state.inputs.id,
                            label: state.inputs.name,
                        },
                        vm,
                    },
                    { root: true }
                )

                dispatch('notification/add', notification, { root: true })
                return false
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async getItemData({ dispatch, commit }, { id }) {
        try {
            const route = await ziggyRoute('client.show', [id])
            const data = await apiGet(route)

            const client = { ...data.address, ...data }
            delete client.address

            Object.keys(DEFAULT_CLIENT_STATE).forEach((el) => {
                commit('SET_INPUT', {
                    name: el,
                    value: client[el],
                })
            })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async handleDelete({ dispatch, commit, state }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiDelete(
                vm.route('client.destroy', { client: state.inputs.id })
            )

            commit('modal/SET_CLOSE', null, { root: true })

            dispatch('hideLoader', null, { root: true })

            if (response.status === 'success') {
                const notification = {
                    type: 'success',
                    text: vm.$t('notifications.success_deleted', {
                        name: state.inputs.name,
                    }),
                }

                dispatch('notification/add', notification, { root: true })

                // remove client item in the view vars state and local storage
                dispatch(
                    'removeItemFromViewVars',
                    {
                        listName: 'client',
                        item: {
                            value: state.inputs.id,
                            label: state.inputs.name,
                        },
                        vm,
                    },
                    { root: true }
                )

                commit('RESET_FORM_STATE')
                vm.$router.push({
                    name: 'ClientIndex',
                })
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async requestDnbFileReport({ dispatch, commit, state }, { client_id, vm }) {
        window.location = vm.route('client.dnb_file_report', {
            client: client_id,
        })
    },

    // Authorized person actions
    async saveAuthPerson({ dispatch, state, commit }, { vm }) {
        try {
            const client_id = state.inputs.id
            const data = {
                ...state.person,
                date_of_birth: format(state.person.date_of_birth, 'Y-MM-dd'),
                client_id,
            }
            const response = await apiPost(vm.route('authorized_person.store'), data)
            commit('SET_PERSON_INPUT', { name: 'id', value: response.id })
            const authorized_people = [...state.inputs.authorized_people, { ...response }]
            commit('UPDATE_AUTH_PEOPLE_ARRAY', authorized_people)
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async updateAuthPerson({ dispatch, commit, state }, { vm }) {
        try {
            const personId = state.person.id
            const client_id = state.inputs.id
            const { passport_file_path, ...person } = state.person
            const data = {
                ...person,
                date_of_birth: format(person.date_of_birth, 'Y-MM-dd'),
                client_id,
            }
            const response = await apiPost(vm.route('authorized_person.update', [personId]), data)

            const updatedPerson = { ...response, passport_file_path }

            const authorized_people = state.inputs.authorized_people.map((person) =>
                person.id === personId ? updatedPerson : person
            )
            commit('UPDATE_AUTH_PEOPLE_ARRAY', authorized_people)
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    uploadPersonPassport({ state, commit, dispatch }, { vm, uppy, closeModal }) {
        const personId = state.person.id

        const tokenMeta = document.querySelector('meta[name="csrf-token"]');
        const headers = {
            'X-CSRF-TOKEN': tokenMeta ? tokenMeta.getAttribute('content') : '',
        };

        uppy.use(XHRUpload, {
            endpoint: vm.route('authorized_person_passport.upload', [personId]),
            method: 'post',
            headers,
            formData: true,
            fieldName: 'passport',
            allowedMetaFields: null,
        })

        uppy.upload()
        uppy.on('upload-success', (file, response) => {
            const authorized_people = state.inputs.authorized_people.map((person) => {
                if (person.id === personId)
                    person.passport_file_path = response.body.passport_file_path
                return person
            })

            commit('UPDATE_AUTH_PEOPLE_ARRAY', authorized_people)
            if (closeModal) {
                commit('modal/SET_CLOSE', null, { root: true })
            }
        })

        uppy.on('error', (er) => {
            dispatch('hideLoader', null, {
                root: true,
            })
        })
    },

    async deleteAuthPerson({ dispatch, state, commit }, { vm, propsToPass }) {
        try {
            const { personId } = propsToPass
            dispatch('showLoader', null, { root: true })
            await apiDelete(vm.route('authorized_person.destroy', [personId]))
            dispatch('hideLoader', null, { root: true })
            const authorized_people = state.inputs.authorized_people.filter(
                (person) => person.id !== personId
            )
            commit('UPDATE_AUTH_PEOPLE_ARRAY', authorized_people)
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async downloadPassport({ dispatch }, { vm, personId, last_name, first_name }) {
        try {
            dispatch('showLoader', null, { root: true })
            const FILE_NAME = `${first_name} ${last_name} passport`
            await apiDownload(
                vm.route('authorized_person_passport.download', [personId]),
                FILE_NAME
            )
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async deletePassport({ dispatch, state, commit }, { vm, personId }) {
        try {
            dispatch('showLoader', null, { root: true })
            await apiDelete(vm.route('authorized_person_passport.destroy', [personId]))
            dispatch('hideLoader', null, { root: true })
            const authorized_people = state.inputs.authorized_people.map((person) => {
                if (person.id === personId) {
                    person.passport_file_path = null
                }
                return person
            })
            commit('UPDATE_AUTH_PEOPLE_ARRAY', authorized_people)
            commit('SET_PERSON_INPUT', { name: 'passport_file_path', value: '' })
        } catch (error) {
            handleError(dispatch, error)
        }
    },
}

const getters = {}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
