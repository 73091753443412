<template>
    <span class="value-missing text-200">{{ $t('§ui.value_missing') }}</span>
</template>

<script>

export default {
    name: 'CoreValueMissing'
}
</script>

<style lang="scss">
.value-missing {
    color: $cs-25;
}
</style>
