<template>
    <div class="contract-files-modal">
        <DataTable
            :value="props.files"
            responsiveLayout="scroll"
            sortField="created_at"
            :sortOrder="1"
        >
            <Column
                field="name"
                :header="$t('§invoices.tab_table_header_file')"
            >
                <template #body="slotProps">
                    <CoreTableCellButton
                        :label="slotProps.data.name"
                        @click="getFile(slotProps.data)"
                    />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import CoreTableCellButton from '@/components/core/CoreTableCellButton'
import CoreValueMissing from '@/components/core/CoreValueMissing'
import Tag from 'primevue/tag'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'

export default {
    name: 'ContractFilesModal',
    
    components: {
        CoreTableCellButton,
        CoreValueMissing,
        Tag,
        Column,
        DataTable,
    },
    
    data() {
        return {
            props: this.$store.state.modal.data,
        }
    },

    methods: {
        getFile(file) {
            this.$store.dispatch('invoices/downloadInvoiceFile', {
                vm: this,
                invoiceId: this.props.invoiceId,
                fileId: file.id,
                fileName: file.name,
            })
        },

    },
}
</script>

<style lang="scss">
.contract-files-modal {
    padding: 40px;
}
.contract-files-modal-item-row {
    padding: 0.6rem 0.8rem;

    &.total-row span{
        font-weight: bold;
    }

    &:nth-child(even) {
        background-color: $cn-75;
        border-radius: 4px;
    }
}

.contract-files-modal-total-row {
    padding: 0.6rem 0.8rem;
    margin-top: 1rem;

    span {
        font-weight: bold;
        font-size: 2.3rem;
    }
}
</style>

