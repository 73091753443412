import AuthorizedPersonModal from '@/views/Client/AuthorizedPersonModal.vue' //
import ClientItemModal from '@/views/Client/ClientItemModal.vue' //
import UnitTypeItemModal from '@/views/UnitType/UnitTypeItemModal.vue' //
// import ContractUploadModal from '@/views/Contract/ContractUploadModal.vue' //
import UploadContractFileModal from '@/views/Contract/UploadContractFileModal.vue' //
import InvoiceUploadModal from '@/views/Invoices/InvoiceUploadModal.vue' //
import SelectUnitTypeModal from '@/views/ClickAndFinance/modals/SelectUnitTypeModal.vue' //
import SelectClientModal from '@/views/ClickAndFinance/modals/SelectClientModal.vue' //
import ResetFormModal from '@/views/ClickAndFinance/modals/ResetFormModal.vue' //
import ContractSavedModal from '@/views/ClickAndFinance/modals/ContractSavedModal.vue' //
import ContractApprovalRequestedModal from '@/views/ClickAndFinance/modals/ContractApprovalRequestedModal.vue' //
import CreateTokenModal from '@/views/Token/CreateTokenModal.vue' //
import EquipmentConnectivityModal from '@/views/Unit/components/EquipmentConnectivityModal.vue' //
import EquipmentNotesModal from '@/views/Unit/components/EquipmentNotesModal.vue' //
import InviteItemModal from '@/views/Users/InviteItemModal.vue' //
import MaintenanceModal from '@/views/Contract/MaintenanceModal.vue' //
import ContractSubItemsModal from '@/views/Contract/ContractSubItemsModal.vue' //
import ContractFilesModal from '@/views/Contract/ContractFilesModal.vue' //
import UserRolesModal from '@/views/Users/UserRolesModal.vue' //
import CustomerUidModal from '@/views/Contract/CustomerUidModal.vue' //
import CreateRoleModal from '@/views/Admin/components/CreateRoleModal.vue' //
import EditRoleModal from '@/views/Admin/components/EditRoleModal.vue' //
import ConfirmActionModal from './ConfirmActionModal.vue'
import CreateItemModal from './CreateItemModal.vue'

export default {
    AuthorizedPersonModal,
    ClientItemModal,
    UnitTypeItemModal,
    // ContractUploadModal,
    UploadContractFileModal,
    InvoiceUploadModal,
    SelectUnitTypeModal,
    SelectClientModal,
    ResetFormModal,
    ContractSavedModal,
    ContractApprovalRequestedModal,
    CreateTokenModal,
    EquipmentConnectivityModal,
    InviteItemModal,
    CustomerUidModal,
    MaintenanceModal,
    ContractSubItemsModal,
    ContractFilesModal,
    UserRolesModal,
    ConfirmActionModal,
    CreateItemModal,
    EquipmentNotesModal,
    CreateRoleModal,
    EditRoleModal,
}
