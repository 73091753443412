<template>
    <div>
        <CoreTwoColumnsRow class="mb-5">
            <template #left>
                <CoreBorderWrapper
                    :condensed="true"
                    class="flex-grow-1 flex-shrink-1 flex-basis-55"
                >
                    {{ $t('§contract.customer_uid') }}
                    {{ isFieldRequired('customer_uid') }}
                </CoreBorderWrapper>
            </template>
            <template #right>
                <CoreStringInput
                    name="customer_uid"
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    :condensed="true"
                    :validator="v$.customer_uid"
                    :value="inputs.customer_uid"
                    @input="setInputValue('customer_uid', $event)"
                />
            </template>
        </CoreTwoColumnsRow>

        <div class="flex justify-end flex-gap-2">
            <CoreButtonPrimary
                label="§ui_button.save"
                icon="pi-check"
                :disabled="!isValid"
                @click="handleSave"
            />
            <CoreButtonSecondary
                label="§ui_button.close"
                @click="handleClose"
            />
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import CoreStringInput from '@/components/core/CoreStringInput.vue'
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary.vue'
import CoreButtonSecondary from '@/components/core/CoreButtonSecondary.vue'
import CoreBorderWrapper from '@/components/core/CoreBorderWrapper.vue'
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow.vue'

export default {
    name: 'CustomerUidModal',

    components: {
        CoreStringInput,
        CoreButtonPrimary,
        CoreButtonSecondary,
        CoreBorderWrapper,
        CoreTwoColumnsRow,
    },

    data() {
        return {
            inputs: {
                customer_uid: '',
            },
            v$: {},
        }
    },

    computed: {
        isValid() {
            return !this.v$.$invalid
        },

        rules() {
            return {
                customer_uid: {
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(64),
                },
            }
        },
    },

    created() {
        this.inputs.customer_uid = this.$store.state.contract.contractData.customer_uid
    },

    mounted() {
        this.v$ = useVuelidate(this.rules, this.inputs)
    },

    methods: {
        setInputValue(name, value) {
            this.inputs[name] = value
            this.$nextTick(() => {
                this.v$[name].$touch()
            })
        },

        handleSave() {
            this.$store.dispatch('contract/updateCustomerUid', {
                vm: this,
                formData: {
                    customer_uid: this.inputs.customer_uid,
                },
            })
        },

        handleClose() {
            this.$store.commit('modal/SET_CLOSE')
        },
    },
}
</script>
