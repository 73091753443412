<template>
    <div class="items-search-and-select">
        <div class="items-search-and-select__add-new-button mb-5">
            <CoreLinkSecondary
                class="mb-3"
                text="§ui_button.create_new"
                :disabled="!hasPermission('create equipment type')"
                @click="handleOpenModal"
            />
        </div>

        <hr class="mb-5" />

        <div class="items-search-and-select__search mb-5">
            <CoreStringInput
                v-model="filter"
                :fullWidth="true"
                :placeholder="searchPlaceholder"
                icon="pi-search"
            />
        </div>

        <div class="items-search-and-select__list">
            <div class="items-search-and-select__list-header mb-5">
                <span class="font-bold text-400">{{ $t('§caf.name') }}</span>
                <span class="font-bold text-400">
                    {{ $t('§caf.industry') }}
                </span>
            </div>

            <div v-if="list === null">LOADING</div>

            <div v-else>
                <ItemRow
                    v-for="item in filteredList"
                    :key="item.id"
                    v-model="selected"
                    :item="item"
                    :radioGroupName="radioGroupName"
                />
            </div>
        </div>
    </div>
</template>

<script>
import CoreStringInput from '@/components/core/CoreStringInput'
import CoreLinkSecondary from '@/components/core/CoreLinkSecondary'
import ItemRow from './ItemRow.vue'

export default {
    name: 'ItemsSearchAndSelect',

    components: { ItemRow, CoreStringInput, CoreLinkSecondary },

    props: {
        list: {
            required: true,
            validator(value) {
                if (value === null || Array.isArray(value)) return true
                return false
            },
        },

        radioGroupName: {
            type: String,
            required: true,
        },

        inputName: {
            type: String,
            required: true,
        },

        itemCreateModalName: {
            type: String,
            required: true,
        },

        itemCreateModalTitle: {
            type: String,
            required: true,
        },

        searchPlaceholder: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            filter: '',
        }
    },

    computed: {
        filteredList() {
            return this.list.filter((item) => item.name.includes(this.filter))
        },

        selected: {
            get() {
                return this.$attrs.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },

    methods: {
        handleOpenModal() {
            if (this.hasPermission('create equipment type')) {
                this.$store.commit('modal/SET_CLOSE')

                this.$store.commit('modal/SET_OPEN', {
                    name: this.itemCreateModalName,
                    title: this.itemCreateModalTitle,
                    size: 'large',
                })
            }
        },
    },
}
</script>

<style scoped lang="scss">
.items-search-and-select {
    &__list-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        & > span {
            flex: 1 1 50%;
        }
    }

    &__list {
        height: 34rem;
        overflow: auto;
        padding: 0.5rem;
    }
}
</style>
