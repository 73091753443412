<template>
    <div>
        <CoreTwoColumnsRow>
            <template #left>
                <CoreBorderWrapper
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    :style="{
                        'max-width': '500px',
                    }"
                >
                    <span>
                        {{ $t('§users.invite_email_address') }}
                    </span>
                </CoreBorderWrapper>
            </template>
            <template #right>
                <CoreStringInput
                    class="flex-grow-1 flex-shrink-1 flex-basis-55"
                    :value="invite_email"
                    placeholder="§ui.placeholder_input_email"
                    :disabled="true"
                />
            </template>
        </CoreTwoColumnsRow>

        <CoreTwoColumnsRow>
            <template #left>
                <CoreBorderWrapper
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    :style="{
                        'max-width': '500px',
                    }"
                >
                    <span>
                        {{ $t('§users.invite_created_at') }}
                    </span>
                </CoreBorderWrapper>
            </template>
            <template #right>
                <CoreStringInput
                    class="flex-grow-1 flex-shrink-1 flex-basis-55"
                    :value="invite_created_at"
                    placeholder="§ui.placeholder_created_at"
                    :disabled="true"
                />
            </template>
        </CoreTwoColumnsRow>

        <CoreTwoColumnsRow>
            <template #left>
                <CoreBorderWrapper
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    :style="{
                        'max-width': '500px',
                    }"
                >
                    <span>
                        {{ $t('§users.valid_until') }}
                    </span>
                </CoreBorderWrapper>
            </template>
            <template #right>
                <CoreStringInput
                    class="flex-grow-1 flex-shrink-1 flex-basis-55"
                    :value="validUntil"
                    placeholder="§ui.placeholder_valid_until"
                    :disabled="true"
                />
            </template>
        </CoreTwoColumnsRow>

        <CoreTwoColumnsRow>
            <template #left>
                <CoreBorderWrapper
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    :style="{
                        'max-width': '500px',
                    }"
                >
                    <span>
                        {{ $t('§users.invitation_link') }}
                    </span>
                </CoreBorderWrapper>
            </template>
            <template #right>
                <CoreStringInput
                    class="flex-grow-1 flex-shrink-1 flex-basis-55"
                    :value="invitationLink"
                    placeholder="§ui.placeholder_invitation_link"
                    :disabled="true"
                />
            </template>
        </CoreTwoColumnsRow>

        <div class="mt-4 flex justify-end flex-gap-2">
            <CoreButtonPrimary
                label="§ui_button.resend_invite"
                icon="pi-refresh"
                @click="handleInviteResend"
                :loading="$store.state.invite.loading"
                :disabled="!invite_expired || !!invite_accepted || $store.state.invite.loading"
            />

            <CoreButtonPrimary
                label="§ui_button.delete"
                icon="pi-times"
                class="p-button-danger"
                :disabled="isDeleteButtonDisabled"
                @click="handleDelete"
            />

            <CoreButtonSecondary
                label="§ui_button.close"
                :disabled="$store.state.invite.deleting"
                @click="handleClose"
            />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow.vue'
import CoreBorderWrapper from '@/components/core/CoreBorderWrapper.vue'
import CoreStringInput from '@/components/core/CoreStringInput.vue'
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary'
import CoreButtonSecondary from '@/components/core/CoreButtonSecondary'

export default {
    name: 'InviteItemModal',

    components: {
        CoreTwoColumnsRow,
        CoreBorderWrapper,
        CoreStringInput,
        CoreButtonPrimary,
        CoreButtonSecondary,
    },

    data() {
        return {
            id: null,
            invite_email: null,
            invite_created_at: null,
            validUntil: null,
            invite_expired: null,
            invite_accepted: null,
            invitationLink: null,
            user_id: null,
            user_email: null,
            user_name: null,
        }
    },

    computed: {
        isDeleteButtonDisabled() {
            return (
                !(!this.invite_accepted && this.invite_expired) || this.$store.state.invite.deleting
            )
        },
    },

    mounted() {
        this.handleData({ ...this.$store.state.modal.data })
    },

    methods: {
        ...mapActions({
            showNotification: 'notification/add',
            resendInvite: 'invite/resendInvite',
        }),

        handleResend() {
            this.resendInvite({ vm: this, id: this.id })
        },

        handleClose() {
            this.$emit('close-modal')
        },

        handleData(data) {
            this.id = data.id
            this.invite_email = data.invite_email
            this.invite_created_at = data.invite_created_at
            this.validUntil = data.validUntil
            this.invite_expired = data.invite_expired
            this.invite_accepted = data.invite_accepted
            this.invitationLink = data.token
                ? `${window.location.origin}/invited/${data.token}`
                : ''
            this.user_id = data.user_id
            this.user_email = data.user_email
            this.user_name = data.user_name
        },

        callCopyOrSelectAction(propName) {
            if (navigator.clipboard) {
                this.copyToClipboard(propName)
            } else {
                this.$refs.invite.select()
            }
        },

        async copyToClipboard(propName) {
            const triggerNotification = (notification) => {
                this.showNotification(notification)
            }

            const isAllowed = await this.checkClipboardPermission()

            if (isAllowed) {
                navigator.clipboard.writeText(this[propName]).then(
                    function () {
                        triggerNotification({
                            type: 'success',
                            text: 'notifications.success_clipboard_copy',
                        })
                    },
                    function () {
                        triggerNotification({
                            type: 'error',
                            text: 'notifications.error_clipboard_copy',
                        })
                    }
                )
            }
        },

        checkClipboardPermission() {
            // firefox has clipboard-write permission true by default
            if (navigator.userAgent.indexOf('Firefox') > -1) {
                return true
            }

            // safari does not support permission API
            if (navigator.userAgent.indexOf('Safari') > -1) {
                return true
            }

            const result = navigator.permissions.query({
                name: 'clipboard-write',
            })

            return result
        },

        handleInviteResend() {
            this.$store.dispatch('invite/resendInvite', { vm: this, id: this.id })
            this.$store.commit('modal/SET_CLOSE')
        },

        async handleDelete() {
            await this.$store.dispatch('invite/deleteInvite', { vm: this, id: this.id })
            this.$store.commit('modal/SET_CLOSE')
        },
    },
}
</script>
