<template>
    <div class="user-roles-modal">
        <CorePageLoader :loading="loading" />
        <div class="roles-container">
            <div class="roles-row">
                <div class="role-name-col">{{ $t('§permissions.table_header_role') }}</div>
                <div class="role-col">{{ $t('§permissions.table_header_is_role_assigned') }}</div>
            </div>

            <div
                v-for="(isRoleApplied, roleName) in roles"
                :key="roleName"
                class="roles-row"
            >
                <div class="role-name-col">

                    {{ $t('§permissions.role_' + roleName) }}
                </div>
                <div class="role-col">
                    <CoreCheckbox
                        :value="isRoleApplied === 1"
                        @input="toggleRole(roleName)"
                        :name="`role_checkbox_${roleName}`"
                        label=""
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CoreTableCellButton from '@/components/core/CoreTableCellButton'
import CoreValueMissing from '@/components/core/CoreValueMissing'
import CorePageLoader from '@/components/core/CorePageLoader'
import CoreCheckbox from '@/components/core/CoreCheckbox'
import Tag from 'primevue/tag'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'

export default {
    name: 'UserRolesModal',
    
    components: {
        CoreTableCellButton,
        CoreValueMissing,
        CorePageLoader,
        CoreCheckbox,
        Tag,
        Column,
        DataTable,
    },
    
    data() {
        return {
            props: this.$store.state.modal.data,
            loading: true,
            roles: {},
        }
    },

    computed: {
        userID() {
            return this.props.userID;
        }
    },

    mounted() {
        this.getUserRoles();
    },

    methods: {
        async getUserRoles() {
            this.roles = await this.$store.dispatch('user/getUserRoles', {
                vm: this,
                userID: this.userID
            });
            this.loading = false;
        },

        async toggleRole(roleName) {
            this.loading = true;
            await this.$store.dispatch('user/toggleUserRole', {
                vm: this,
                userID: this.userID,
                roleName
            });
            this.$root.$emit('reload-users-list');
            await this.getUserRoles();
        },
    },
}
</script>

<style lang="scss">
.user-roles-modal {
    padding: 40px;
}

.roles-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 300px;
    box-sizing: border-box;
}

.roles-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
}

.roles-row:nth-child(even) {
    background-color: $cn-50;
}

.role-name-col, .role-col {
    flex: 1;
    display: flex;
    border: none;
    box-sizing: border-box;
    margin: 0;
    padding: 8px;
}
.role-name-col {
    text-align: left;
    text-transform: capitalize;
    font-weight: bold;
}
.role-col {
    text-align: center;
    justify-content: center;
    align-items: center;
}
</style>
